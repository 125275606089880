<template>
  <!-- 在工地报表 -->
  <el-card id="printContent" :body-style="{ padding: '5px' }">
    <div slot="header" style="height:20px;line-height:20px;text-align:center;padding:0px">
      <div style="float:left">
        <el-button type="primary" size="small" @click="printContent()">打印</el-button>
        <download-excel style="display:inline-block" :data="tableData" :fields="json_fields" :header="title" :footer="footer" :name="title + '.xlsx'">
          <el-button type="success" size="small">导出</el-button>
        </download-excel>
      </div>
      <span style="font-weight:bold;font-size: 20px;color: blue;">{{ thedate }} 各段工地钢板桩数量报表</span>
      <div style="float:right">
        <el-date-picker
          size="small"
          type="date"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          v-model="thedate"
          style="width: 150px;"
          @change="doChange"
        >
        </el-date-picker>
      </div>
    </div>

    <el-table
      id="mytable"
      v-loading="loading"
      :data="tableData"
      border
      stripe
      highlight-current-row
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      style="width: 100%;"
    >
      <el-table-column type="index" width="43"> </el-table-column>
      <el-table-column v-for="item in labels" :key="item" :label="item" :prop="item" :width="item === '工地' ? 82 : item === '小计' ? 83 : 72">
      </el-table-column>
    </el-table>
    <div class="footer">
      <span style="font-weight:bold;font-size: 20px;">当日出库总数量：{{ sumChuku }} , 当日回库总数量：{{ sumHuiku }} </span>
    </div>
  </el-card>
</template>

<script>
import utils from '@/assets/js/utils'
// import FileSaver from 'file-saver'
// import XLSX from 'xlsx'

export default {
  data() {
    return {
      title: '',
      footer: '页脚',
      json_fields: {
        工地: '工地',
        '575-6米': '575-6米',
        '575-9米': '575-9米',
        '575-12米': '575-12米',
        '575-15米': '575-15米',
        '400-9米': '400-9米',
        'L400-6米': 'L400-6米',
        'L400-9米': 'L400-9米',
        'L400-12米': 'L400-12米',
        '角板575-6米': '角板575-6米',
        '角板575-9米': '角板575-9米',
        '角板575-12米': '角板575-12米',
        '角板L400-12米': '角板L400-12米',
        '角板L400-9米': '角板L400-9米',
        '角板L400-6米': '角板L400-6米',
        '角板400-9米': '角板400-9米',
        '角板400-12米': '角板400-12米',
        '斜板575-6米': '斜板575-6米',
        '斜板575-9米': '斜板575-9米',
        '斜板575-12米': '斜板575-12米',
        '牙条角板L400-9米': '牙条角板L400-9米',
        '牙条角板L400-12米': '牙条角板L400-12米',
        '牙条角板L400-6米': '牙条角板L400-6米',
        '牙条角板575-6米': '牙条角板575-6米',
        '牙条角板575-9米': '牙条角板575-9米',
        小计: '小计'
      },
      thedate: utils.getDate(),
      tableData: [],
      sumChuku: 0,
      sumHuiku: 0,
      labels: [],
      loading: false,
      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      TableHeaderRowStyle() {
        return 'height:35px'
      },
      TableHeaderCellStyle() {
        return 'padding-bottom:4px;padding-top:4px;font-size:15px;font-weight:700;color:#000;'
      },
      TableRowStyle() {
        return 'height:33px'
      },
      TableCellStyle() {
        return 'padding:0px;font-size:17px;color:#000;height:33px'
      }
    }
  },
  computed: {
    totalKucun: function() {
      return Number(this.sumKucun.zong_kucun) + Number(this.sumKucun.zong_ingongdi_amount) + Number(this.sumKucun.zong_onroad_amount)
    }
  },
  methods: {
    getTableData() {
      this.loading = true
      this.$axios
        .get('/ingongdiBaobiao/', { params: { date: this.thedate } })
        .then(res => {
          this.tableData = res.data.tableData
          this.sumChuku = res.data.sumChuku
          this.sumHuiku = res.data.sumHuiku
          this.labels = res.data.labels
          this.loading = false
          console.log('tableData', this.tableData)
          console.log('len of labels:', this.labels.length)
          this.title = this.thedate + '各段工地钢板桩数量报表'
          this.footer = '当日出库总数量：' + this.sumChuku + ', 当日回库总数量：' + this.sumHuiku
          console.log('sumChuku:', this.sumChuku)
          console.log('sumHuiku:', this.sumHuiku)
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    doChange() {
      this.getTableData()
    },
    //导出Excel
    // exportToExcel() {
    //   let et = XLSX.utils.table_to_book(document.getElementById('mytable')) //此处传入table的DOM节点
    //   let etout = XLSX.write(et, {
    //     bookType: 'xlsx',
    //     bookSST: true,
    //     type: 'array'
    //   })
    //   try {
    //     FileSaver.saveAs(
    //       new Blob([etout], {
    //         type: 'application/octet-stream'
    //       }),
    //       '各段工地钢板桩数量报表.xlsx'
    //     ) //trade-publish.xlsx 为导出的文件名
    //   } catch (e) {
    //     console.log(e, etout)
    //   }
    //   return etout
    // },
    printContent() {
      utils.printContent('printContent')
    }
  },
  created() {
    this.getTableData()
  }
}
</script>

<style scoped>
.footer {
  margin-top: 6px;
  margin-left: 50px;
}
::v-deep .el-table td,
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border,
::v-deep .el-table--group {
  border-color: black;
}
::v-deep .el-table--border::after,
::v-deep .el-table--group::after,
::v-deep .el-table::before {
  background-color: black;
}
</style>

<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
